import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TuldisOpts } from "hooks/dropdownOptions/filterTuldisOpts";

import { BasicSelectOpt } from "types";

type TReportFilter = {
  letterTypeClass?: BasicSelectOpt<string> | null;
  tuldisUser?: TuldisOpts | null;
  startDate?: string;
  endDate?: string;
  reportDate?: string;
};
export interface reportState {
  filters: TReportFilter;
}

const INITIAL_STATE = {
  letterTypeClass: null,
  tuldisUser: null,
  startDate: "",
  endDate: "",
  reportDate: "",
};

const initialState: reportState = {
  filters: INITIAL_STATE,
};

type TUpdatePayload<T> = {
  key: keyof T;
  value: any;
};

export const reportSlice = createSlice({
  name: "REPORT_SLICE",
  initialState,
  reducers: {
    storeReportFilter: (
      state,
      payload: PayloadAction<Pick<reportState, "filters">>,
    ) => {
      state.filters = payload.payload.filters;
    },
    updateReportFilter: (
      state,
      action: PayloadAction<TUpdatePayload<TReportFilter>>,
    ) => {
      const { key, value } = action.payload;
      state.filters[key] = value; // Dynamically update the field
    },
    removeReportFilter: (state) => {
      state.filters = INITIAL_STATE;
    },
  },
});

export default reportSlice.reducer;
export const { storeReportFilter, removeReportFilter, updateReportFilter } =
  reportSlice.actions;
