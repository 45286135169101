import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import userStore from "./userStore";
import sidebarStore from "./sidebarStore";
import reportStore from "./sisfotu/reportStore";
import { userStoreAPI } from "./userStore/userStoreAPI";
import { personelStoreAPI } from "./sisfopers/personelStore/personelStoreAPI";
import { usertypeStoreAPI } from "./usertypeStore/usertypeStoreAPI";
import { letterStoreAPI } from "./sisfotu/letterStoreAPI/letterStoreAPI";
import { userPersonelStoreAPI } from "./sisfotu/userPersonelStoreAPI";
import { letterCategoryStoreAPI } from "./sisfotu/letterCategoryAPI";
import { lovStoreAPI } from "./sisfopers/lovStore/lovStoreAPI";
import { esignatureStoreAPI } from "store/sisfotu/esignatureStoreAPI";
import { notificationStoreAPI } from "./notificationStore/notificationStoreAPI";
import { masterDataWorkUnitStoreAPI } from "./sisfopers/masterDataStore/workunit/masterDataWorkUnitStoreAPI";
import { operationalStoreAPI } from "./sisfotu/operationalStoreAPI";
import { letterTemplateStoreAPI } from "./sisfotu/letterTemplateStoreAPI";
import { letterInternalStoreAPI } from "./sisfotu/letterInternalStoreAPI";
import { letterExternalStoreAPI } from "./sisfotu/letterExternalStoreAPI";
import { letterReportStoreAPI } from "./sisfotu/letterReportStoreAPI";
import { takahStoreAPI } from "./sisfotu/takahStore/takahStoreAPI";
import { issueStoreAPI } from "./sisfotu/issueStore/issueStoreAPI";
import { satkerStoreAPI } from "./sisfotu/satkerAPI/satkerStoreAPI";
import { positionStoreAPI } from "./sisfopers/positionStore/positionStoreAPI";
import { roleStoreAPI } from "./sisfotu/roleStore/roleStoreAPI";
import { letterTypeClassStoreAPI } from "./sisfotu/letterTypeClassStoreAPI";
import { letterPositionStoreAPI } from "./sisfotu/letterPositionStoreAPI";

export const store = configureStore({
  /**
   * @todo sort these reducer slices alphabetically, ascending.
   */
  reducer: {
    userStore,
    sidebarStore,
    reportStore,
    [lovStoreAPI.reducerPath]: lovStoreAPI.reducer,
    [masterDataWorkUnitStoreAPI.reducerPath]:
      masterDataWorkUnitStoreAPI.reducer,
    [userPersonelStoreAPI.reducerPath]: userPersonelStoreAPI.reducer,
    [operationalStoreAPI.reducerPath]: operationalStoreAPI.reducer,
    [personelStoreAPI.reducerPath]: personelStoreAPI.reducer,
    [letterStoreAPI.reducerPath]: letterStoreAPI.reducer,
    [esignatureStoreAPI.reducerPath]: esignatureStoreAPI.reducer,
    [letterCategoryStoreAPI.reducerPath]: letterCategoryStoreAPI.reducer,
    [letterTemplateStoreAPI.reducerPath]: letterTemplateStoreAPI.reducer,
    [userStoreAPI.reducerPath]: userStoreAPI.reducer,
    [usertypeStoreAPI.reducerPath]: usertypeStoreAPI.reducer,
    [notificationStoreAPI.reducerPath]: notificationStoreAPI.reducer,
    [letterInternalStoreAPI.reducerPath]: letterInternalStoreAPI.reducer,
    [letterExternalStoreAPI.reducerPath]: letterExternalStoreAPI.reducer,
    [letterReportStoreAPI.reducerPath]: letterReportStoreAPI.reducer,
    [takahStoreAPI.reducerPath]: takahStoreAPI.reducer,
    [issueStoreAPI.reducerPath]: issueStoreAPI.reducer,
    [satkerStoreAPI.reducerPath]: satkerStoreAPI.reducer,
    [positionStoreAPI.reducerPath]: positionStoreAPI.reducer,
    [roleStoreAPI.reducerPath]: roleStoreAPI.reducer,
    [letterTypeClassStoreAPI.reducerPath]: letterTypeClassStoreAPI.reducer,
    [letterPositionStoreAPI.reducerPath]: letterPositionStoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo sort these middlewares alphabetically, ascending.
     */
    let middlewares = getMiddleWares().concat([
      lovStoreAPI.middleware,
      letterStoreAPI.middleware,
      personelStoreAPI.middleware,
      userPersonelStoreAPI.middleware,
      letterCategoryStoreAPI.middleware,
      letterTemplateStoreAPI.middleware,
      esignatureStoreAPI.middleware,
      userStoreAPI.middleware,
      usertypeStoreAPI.middleware,
      notificationStoreAPI.middleware,
      masterDataWorkUnitStoreAPI.middleware,
      operationalStoreAPI.middleware,
      letterInternalStoreAPI.middleware,
      letterExternalStoreAPI.middleware,
      letterReportStoreAPI.middleware,
      takahStoreAPI.middleware,
      issueStoreAPI.middleware,
      satkerStoreAPI.middleware,
      positionStoreAPI.middleware,
      roleStoreAPI.middleware,
      letterTypeClassStoreAPI.middleware,
      letterPositionStoreAPI.middleware,
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
